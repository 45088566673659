import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

const ratingAggregatesSchema = z.object({
  numberOfRatings: z.number(),
  avgRating: z.number(),
  ratingsBreakdown: z.array(
    z.object({
      rating: z.union([z.literal(1), z.literal(2), z.literal(3), z.literal(4), z.literal(5)]),
      numberOfRatings: z.number(),
    })
  ),
});

export type RatingAggregates = z.infer<typeof ratingAggregatesSchema>;

const getRatingAggregatesResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    attributes: ratingAggregatesSchema,
  }),
});

export type GetRatingAggregatesResponse = z.infer<typeof getRatingAggregatesResponseSchema>;

function getRatingAggregatesGetUrl(workplaceId: string): string {
  return `${environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL}/workplaces/${workplaceId}/rating-aggregates`;
}

export function useGetRatingAggregates(
  workPlaceId: string,
  options: UseQueryOptions<RatingAggregates, AxiosError> = {}
): UseQueryResult<RatingAggregates> {
  return useQuery({
    queryKey: [getRatingAggregatesGetUrl(workPlaceId)],
    queryFn: async () => {
      const response = await get({
        url: getRatingAggregatesGetUrl(workPlaceId),
        responseSchema: getRatingAggregatesResponseSchema,
      });
      return response.data.data.attributes;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_RATING_AGGREGATES_FAILURE,
    },
    ...options,
  });
}
